import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import ModernImg from "../../components/ModernImg"
import SinglePageHeader from "../../components/SinglePageLayout"

export default function CSR() {
  const { t } = useTranslation()

  const FAQ = [
    {
      q: t("faq-1-q"),
      a: t("faq-1-a"),
    },
    {
      q: t("faq-2-q"),
      a: t("faq-2-a"),
    },
    {
      q: t("faq-3-q"),
      a: t("faq-3-a"),
    },
  ]

  const bursaries = [
    {
      title: `ICH ${t("open-bursary")}`,
      summary: t("all-levels"),
      icon: "",
      link: "/csr/bursary-all-levels",
    },
    {
      title: "Letter to ICH Bursary Recipients",
      summary: t("all-levels"),
      icon: "",
      link: "/csr/letter-to-bursary-recipients/",
      reload: true,
    },
    {
      title: `${t("bursary-nus-title")}`,
      summary: t("nus"),
      icon: "",
      link: "/csr/bursary-group-nus/",
    },
    {
      title: `${t("bursary-ntu-title")}`,
      summary: t("ntu"),
      icon: "",
      link: "/csr/bursary-group-ntu/",
    },
    {
      title: `${t("bursary-sit-title")}`,
      summary: t("SIT"),
      icon: "",
      link: "/csr/bursary-group-sit/",
    },
    {
      title: `${t("bursary-ite-title")}`,
      summary: t("ITE"),
      icon: "",
      link: "/csr/bursary-group-ite/",
    },
  ]

  return (
    <SinglePageHeader title={t("csr-long")} bg="/assets/img/csr-banner.jpg">
      <section className="ich-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h2 className="title">ICH {t("bursary")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <p>
                {t("csr-page-text-1")}
                {t("csr-page-text-2")}
              </p>
              <ul>
                <li>{t("assesment-a")}</li>
                <li>{t("assesment-b")}</li>
                <li>{t("assesment-c")}</li>
                <li>{t("assesment-d")}</li>
              </ul>
              <div className="service-accordion">
                <h5>{t("faq")}</h5>
                {FAQ.map((item, index) => {
                  const id = "faqq" + index
                  return (
                    <div className="card" key={id}>
                      <div className="card-header">
                        <h6>
                          <button data-toggle="collapse" data-target={`#${id}`}>
                            {item.q}
                          </button>
                        </h6>
                      </div>
                      <div id={id} className="collapse">
                        <div className="card-body">{item.a}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey ich-container" style={{ maxWidth: "none" }}>
        <div className="container" style={{ maxWidth: 960 }}>
          <div className="row justify-content-center">
            {bursaries.map(item => {
              return (
                <div className="col-lg-6 col-sm-6" key={item.link}>
                  <div className="single-industries-wrap">
                    <div className="media">
                      <div className="thumb">
                        <ModernImg
                          src={item.icon || "/assets/img/industries/3.png"}
                          alt={item.title}
                        />
                      </div>
                      <div className="single-industries-details media-body">
                        <h6>
                          {item.reload ? (
                            <a href={item.link}>{item.title}</a>
                          ) : (
                            <Link to={item.link}>{item.title}</Link>
                          )}
                        </h6>
                        <p>{item.summary}</p>
                      </div>
                    </div>
                    {item.reload ? (
                      <a className="details-btn" href={item.link}>
                        {t("details-here")}
                        <span>
                          <i className="la la-arrow-right"></i>
                        </span>
                      </a>
                    ) : (
                      <Link className="details-btn" to={item.link}>
                        {t("details-here")}
                        <span>
                          <i className="la la-arrow-right"></i>
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </SinglePageHeader>
  )
}
